<template>
  <div class="vle__header-custom-dropdown">
    <b-dropdown
      right
      variant="link"
      toggle-class="text-decoration-none"
      ref="walkthrowDropdown"
      id="walkthrowDropdown"
      class="custom-dropdown"
      no-caret
    >
      <template #button-content>
        <Icon
          iconName="three-dots-vertical"
          class="three-dots-vertical"
          :rounded="false"
          variant="dark"
          id="selfPlacedMenuIcon"
        >
        </Icon>
      </template>
      <b-dropdown-item class="custom-dropdown-item">
        <div @click="goToCouresData">
          <img
            src="@/assets/images/wrapper-components/alert/information.svg"
            alt="about"
          />
          <span class="vle__list-title"
            >{{ $t("vle.vle_menu.about") }}test</span
          >
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click="startTour" class="custom-dropdown-item">
        <img src="@/assets/images/icons/walkthrough.svg" alt="walkthrough" />
        <span class="vle__list-title">{{
          $t("vle.vle_menu.walkthrough")
        }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="openFeedbackForm"
        class="feedback custom-dropdown-item"
      >
        <img
          class="feedback_svg_image"
          src="@/assets/images/menu/feedback.svg"
        />
        <span class="vle__list-title">{{ $t("my_profile.feedback") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        class="unenroll custom-dropdown-item"
        v-b-modal.unenroll-modal
        v-if="!isCourseStarted()"
      >
        <div class="ac-mc__list" style="color:#FF4040;">
          <img
            src="@/assets/images/icons/unenroll-module.svg"
            alt="unenroll"
            class=" unenroll-image"
          />
          <span class="vle__list-title">{{ $t("course.unenroll.title") }}</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

//Mixins
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
import goToCouresData from "@/components/SelfPaced/mixins/userLayout";
export default {
  mixins: [openFeedbackForm, goToCouresData],
  computed: {
    ...mapGetters(["getSelectedCourse"])
  },
  methods: {
    isCourseStarted() {
      if (this.getSelectedCourse.sections) {
        return this.getSelectedCourse.sections.some(a => a.resumeBlock);
      }
    },
    startTour() {
      // Setting the active tab to learning before starting the tour
      this.$store.commit("SET_CURRENT_MENU_ITEM", "modules");
      this.$emit("startTour");
    }
  }
};
</script>
<style lang="scss" scoped>
.vle__header-custom-dropdown {
  .ac-mc__list {
    color: $brand-error-400;
  }

  .btn {
    padding: 0px !important;
  }

  .dropdown-menu {
    top: 13px !important;
  }

  .btn.focus {
    box-shadow: none !important;
  }

  .dropdown-item {
    padding: 0.5rem 0.5rem;

    &:active {
      color: #16181b;
      text-decoration: none;
      background-color: #e9ecef;
    }
  }

  .feedback .dropdown-item:active .feedback_svg_image {
    filter: invert(35%) sepia(36%) invert(37%) sepia(58%) saturate(5230%)
      hue-rotate(198deg) brightness(104%) contrast(104%);
  }

  .feedback {
    .dropdown-item {
      .feedback_svg_image {
        padding-right: 10px;
      }

      &:active {
        color: $brand-primary-400;
        text-decoration: none;
        background-color: $brand-primary-100;
      }

      &:hover {
        text-decoration: none;
        background-color: $brand-primary-100;
      }

      &:focus {
        color: $brand-primary-400;
        text-decoration: none;
        background-color: $brand-primary-100;
      }
    }
  }

  .unenroll {
    .dropdown-item {
      .unenroll-image {
        padding-right: 0px;
      }

      &:hover {
        background: $brand-error-100;
      }

      &:focus {
        background: $brand-error-100;
      }

      &:active {
        background: $brand-error-100;
      }
    }
  }

  .three-dots-vertical {
    padding: 6px;
    width: 40px;
    height: 40px;
    background: $brand-neutral-50;
    border-radius: 8px;

    &:active {
      background: $brand-primary-100;
    }
  }
}
</style>
